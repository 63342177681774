<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-bold text-center">
          Peace House Agricultural Training Institute (PHATI) <br>
          Reference Details ({{ shortlistedReferences[0].application_year }})
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center" v-for="(ref, idx) in shortlistedReferences" :key="idx">      
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Application No: </span>
        {{ ref.application_no }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant Name: </span>
        {{ ref.surname }} {{ ref.other_names }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Name: </span>
        {{ ref.referee_name }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Email: </span>
        {{ ref.referee_email }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Phone Number: </span>
        {{ ref.referee_phoneno }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Address: </span>
        {{ ref.referee_address }}
      </v-col>      
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Reference Applicant fullname: </span>
        {{ ref.applicant_fullname }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Time length you have known applicant: </span>
        {{ ref.time_length }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">In what capacity do you know the applicant: </span>
        {{ ref.capacity}}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Salvation experience: </span>
        {{ ref.salvation_experience }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant walk with God: </span>
        {{ ref.walk_with_God }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant Strength: </span>
        {{ ref.strength }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant weakness or area for development: </span>
        {{ ref.weaknesses }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Exceptional ability or performance: </span>
        {{ ref.exceptional_ability }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Ability to work independently or as team member: </span>
        {{ ref.work_ability }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Comment on other areas: </span>
        {{ ref.other_areas }}
      </v-col>
      <hr />
    </v-row>
    
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";

export default {
  name: "ReferenceDetails",
  mixins: [snackMixin],
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      shortlistedApplicants: [],
    }
  },
  async created() {
      this.loading = true;
      try {
        const res = await apiClient.get("/applicants.php?u=2");
        if (Array.isArray(res.data)) {
          this.shortlistedApplicants = res.data.filter( applicant => !!applicant.shortlisted );
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err.message, "error");
      }
      this.loading = false;
    },
  computed: {
    // shortlistedApplicants(){
    //   return this.applicants.filter( applicant => !!applicant.shortlisted );
    // },
    shortlistedReferences () { 
      const references = [] 
      this.shortlistedApplicants.forEach( ap => { 
        const {application_no, surname, other_names, application_year, referees} = ap;
        const validRef = Array.isArray( referees ) && referees.length > 1;
        if ( validRef ) {
          if ( referees[ 0 ].submitted ) {
            let ref1 = {application_no, surname, other_names, application_year, ...referees[ 0 ]}
            references.push(ref1)
          }
          if ( referees[ 1 ].submitted ) {
            let ref2 = {application_no, surname, other_names, application_year, ...referees[ 1 ]}
            references.push(ref2)
          }
        }
      });    

      function compareSort ( a, b ) {
        if ( a.surname < b.surname ) {
          return -1;
        }
        if ( a.surname > b.surname ) {
          return 1;
        }
        return 0;
      }

      references.sort( compareSort )
      
      return references;
    } 
  },
  }
</script>
